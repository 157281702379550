<template>
  <div class="chat-dashboard--container flex flex-row">
    <div
      v-if="!smallFormat || !selectedRefId"
      class="chats-list"
      :style="!smallFormat ? 'width: 25% !important' : 'width: 100% !important'"
    >
      <spinner
        v-if="$store.state.chat.chatsLoading"
        :loading="true"
        size="3em"
        style="margin-top: 1em"
      />
      <div v-else-if="!Object.keys(chatSummary).length" style="margin-top: 1em">
        No active chats.
      </div>
      <ScrollContainer v-else class="flex flex-column chat-list-scroll-container scrollbar-hide">
        <card-list style="border-radius: 0">
          <card-list-item
            v-for="chat in Object.values(chatSummary)"
            :key="chat.refId"
            class="chat-list-item"
            :class="{ 'selected-chat': chat.refId === selectedRefId }"
            @click="selectChat(chat.refId)"
          >
            <span class="dot" v-if="!isRead(chat)" />
            <div class="chat-list-item-name">{{ chat.chatName }}</div>
            <div class="chat-list-item-user-names">{{ getChatUsers(chat) }}</div>
          </card-list-item>
        </card-list>
      </ScrollContainer>
    </div>
    <div
      v-if="Object.keys(chatSummary).length && (selectedRefId || !smallFormat)"
      class="chat"
      :style="!smallFormat ? 'width: 75% !important' : 'width: 100% !important'"
    >
      <div
        v-if="smallFormat && selectedRefId"
        class="flex flex-row justify-between chat-go-back-container"
      >
        <div @click="goBack"><font-awesome-icon icon="arrow-left" /> Back</div>
        <div>
          {{ chatSummary[selectedRefId].chatName }}
        </div>
      </div>
      <Chat
        :refId="selectedRefId"
        :editable="true"
        :chatDashboard="true"
        :contractorId="userId"
        :companyId="companyId"
      />
    </div>
  </div>
</template>

<script>
import Chat from '../chat/Chat.vue'
import { useMediaQuery } from '@/composables/mediaQuery'

export default {
  name: 'ChatDashboardBody',
  components: {
    Chat
  },
  setup() {
    const { smallFormat } = useMediaQuery()

    return {
      smallFormat
    }
  },
  data() {
    return {
      selectedRefId: null
    }
  },
  methods: {
    selectChat(refId) {
      if (this.selectedRefId === refId) {
        this.$store.dispatch('updateLastTimeActive', {
          refId,
          userId: this.userId,
          userName: this.userName
        })
        return
      }
      this.selectedRefId = refId
    },
    goBack() {
      this.selectedRefId = null
    },
    isRead(chat) {
      if (!chat || !chat.messages || !chat.chatUsers) return true
      let user
      chat.chatUsers.forEach((chatUser) => {
        if (chatUser.userId === this.userId) user = chatUser
      })

      const message = chat.messages.messageList[chat.messages.messageList.length - 1]

      if (!message) return true

      if (
        !user ||
        (message.userId !== this.userId &&
          (!user.lastTimeActive || user.lastTimeActive < message.createdAt))
      ) {
        return false
      }

      return true
    },
    getChatUsers(chat) {
      let chatUsersNames = ''
      if (!chat) return chatUsersNames
      chat.chatUsers.forEach((chatUser) => {
        if (chatUser.userName && chatUser.userId !== this.userId) {
          if (!chatUsersNames.length) {
            chatUsersNames = chatUser.userName
          } else {
            chatUsersNames = `${chatUsersNames}, ${chatUser.userName}`
          }
        }
      })
      return chatUsersNames
    }
  },
  computed: {
    userId() {
      return this.$store.state.session.user.user_id
    },
    userName() {
      const user = this.$store.state.session.user
      if (!user.user_fname && !user.user_lname) {
        if (user.user_email) {
          return user.user_email
        }
        return this.$t('Anonymous')
      }
      return `${user.user_fname ? user.user_fname : ''} ${user.user_lname ? user.user_lname : ''}`
    },
    companyId() {
      return this.$store.state.session.company.company_id
    },
    chatSummary() {
      return this.$store.state.chat.chatSummary || {}
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.chat-dashboard--container {
  max-width: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
  max-height: 100%;
  min-height: 600px;
  height: 100%;
  border: 2px solid $cool-gray-600;
  overflow: hidden;

  .chats-list {
    background-color: white;
    text-align: center;
    height: 100%;
    border-right: 2px solid $cool-gray-600;

    .chat-list-scroll-container {
      .scroll-container--inner {
        margin-bottom: 5vh;
      }

      .chat-list-item {
        height: 80px;
        padding-top: 20px;
        border-bottom: 2px solid $cool-gray-600;
        border-radius: 0 !important;
        cursor: pointer;
        display: inline-block;
        text-align: start;

        &:hover {
          color: #1f92fc !important; //bootstrap info
        }

        &.selected-chat {
          background-color: #edf8ff;
          color: #1f92fc !important; //bootstrap info
        }

        .dot {
          top: 2.2em;
          position: absolute;
          left: 10px;
          background-color: #1f92fc;
          height: 0.7em;
          width: 0.7em;
          border-radius: 50%;
        }

        .chat-name {
          margin-left: 20px;
          font-size: 0.8em;
          color: $cool-gray-700;
        }

        .chat-list-item-name {
          margin-left: 20px;
          margin-bottom: 5px;
          font-size: 1.1em;
        }

        .chat-list-item-user-names {
          margin-left: 20px;
          font-size: 0.8em;
          color: $cool-gray-600;
        }
      }
    }
  }

  .chat {
    background-color: $flame-white;
    .chat-go-back-container {
      margin: 0.5em;
      background-color: $flame-white;
    }
  }
}
</style>
