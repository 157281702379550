<template>
  <Page title="Chats">
    <ChatDashboardBody v-if="isChatEnabled" style="height: 85%" />
  </Page>
</template>

<script>
import ChatDashboardBody from '../bodies/ChatDashboard.vue'

export default {
  name: 'ChatDashboard',
  components: {
    ChatDashboardBody
  },
  computed: {
    isChatEnabled() {
      const company = this.$store.state.session.company
      return company && company.aoModules.chat === '1'
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
